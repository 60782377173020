.hero-container {
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6rem 4rem;
  overflow: hidden;
}

.hero-content {
  flex: 1;
  max-width: 600px;
  text-align: left;
  padding-right: 2rem;
}

.app-icon {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  margin-bottom: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  color: #333;
  line-height: 1.2;
  font-weight: 800;
}

.hero-content p {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 3rem;
  line-height: 1.6;
}

.hero-btns {
  display: flex;
  gap: 1rem;
}

.download-btn,
.chrome-btn {
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.download-btn {
  background: linear-gradient(135deg, #007aff 0%, #1e90ff 100%);
  color: white;
  box-shadow: 0 8px 15px rgba(0, 122, 255, 0.2);
}

.chrome-btn {
  background: white;
  color: #333;
  border: none;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.download-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 20px rgba(0, 122, 255, 0.3);
}

.chrome-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  transform: perspective(1000px) rotateY(-5deg);
  transition: transform 0.3s ease;
}

.hero-image img:hover {
  transform: perspective(1000px) rotateY(0deg);
}

@media (max-width: 1024px) {
  .hero-container {
    flex-direction: column;
    padding: 4rem 2rem;
    text-align: center;
  }

  .hero-content {
    padding-right: 0;
    margin-bottom: 0;
    text-align: center;
  }

  .hero-btns {
    justify-content: center;
  }

  .hero-image {
    display: none;
  }
}

.hero-section {
  position: relative;
  height: 80vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: white;
}
.hero-overlay h1 {
  font-size: 48px;
  margin-bottom: 20px;
}
.hero-overlay p {
  font-size: 20px;
}
