.features {
  padding: 0;
  position: relative;
  overflow: hidden;
}

.features h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 6rem;
  color: #333;
  font-weight: 800;
}

.features-container {
  max-width: 1400px;
  margin: 0 auto;
}

.feature-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 8rem 2rem;
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.8s ease-out;
}

.feature-section:nth-child(1) {
  background: linear-gradient(180deg, #ffffff 0%, #f8f9fa 100%);
}

.feature-section:nth-child(2) {
  background: linear-gradient(180deg, #f8f9fa 0%, #e9ecef 100%);
}

.feature-section:nth-child(3) {
  background: linear-gradient(180deg, #e9ecef 0%, #dee2e6 100%);
}

.feature-section-visible {
  opacity: 1;
  transform: translateY(0);
}

.feature-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 6rem;
  padding: 0 2rem;
}

.feature-content.image-left {
  flex-direction: row;
}

.feature-content.image-right {
  flex-direction: row-reverse;
}

.feature-content.column-layout {
  flex-direction: column;
  gap: 4rem;
}

.feature-content.column-layout .feature-text {
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
}

.feature-content.column-layout .feature-image {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.feature-text {
  flex: 1;
  max-width: 500px;
}

.feature-text h3 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: 700;
  line-height: 1.4;
}

.feature-text p {
  color: #666;
  line-height: 1.8;
  font-size: 1.2rem;
}

.feature-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.feature-image.mobile-mockup {
  max-width: 300px;
}

.feature-image.extension-mockup {
  max-width: 900px;
  margin: 0 auto;
}

.extension-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  width: 100%;
}

.extension-images img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.extension-images img:hover {
  transform: translateY(-5px);
}

.feature-image img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.1));
  transition: transform 0.3s ease;
  border-radius: 30px;
}

.feature-image img:hover {
  transform: translateY(-10px);
}

@media (max-width: 1024px) {
  .feature-content {
    gap: 3rem;
    padding: 0 1rem;
  }

  .feature-text {
    padding: 0;
    max-width: 100%;
  }

  .feature-text h3 {
    font-size: 2rem;
  }

  .feature-text p {
    font-size: 1.1rem;
  }

  .feature-image.mobile-mockup {
    max-width: 250px;
  }

  .feature-image.extension-mockup {
    max-width: 700px;
  }

  .extension-images {
    flex-direction: column;
    gap: 2rem;
  }

  .extension-images img {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .features {
    padding: 4rem 0;
  }

  .features h2 {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  .feature-section {
    min-height: auto;
    padding: 3rem 1rem;
  }

  .feature-content {
    flex-direction: column !important;
    text-align: center;
  }

  .feature-content.image-left,
  .feature-content.image-right {
    flex-direction: column !important;
  }

  .feature-image.mobile-mockup {
    max-width: 200px;
    margin: 2rem auto;
  }

  .feature-image.extension-mockup {
    max-width: 300px;
    margin: 2rem auto;
  }

  .extension-images {
    gap: 1.5rem;
  }

  .extension-images img {
    max-width: 300px;
  }
}
