.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 6rem 2rem 2rem 2rem;
  line-height: 1.6;
}

.privacy-container h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.privacy-container section {
  margin-bottom: 2rem;
}

.privacy-container h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.privacy-container p {
  margin-bottom: 1rem;
  color: #666;
}

.privacy-container ul {
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.privacy-container li {
  margin-bottom: 0.5rem;
  color: #666;
}
