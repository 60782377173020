.footer {
  background-color: #1e1e1e;
  color: #fff;
  padding: 3rem 2rem 1.5rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 3rem;
  margin-bottom: 2rem;
}

.footer-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-section h4 {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.footer-section p {
  color: #999;
  line-height: 1.5;
  margin-bottom: 0.8rem;
  font-size: 0.95rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-section ul li {
  margin: 0;
}

.footer-section ul li a {
  color: #999;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.9rem;
  padding: 0.2rem 0;
  display: inline-block;
}

.footer-section ul li a:hover {
  color: #007aff;
}

.footer-bottom {
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom p {
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .footer-section:first-child {
    grid-column: 1 / -1;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 3rem 1.5rem 1.5rem;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footer-section {
    text-align: center;
  }

  .footer-list {
    align-items: center;
  }
}
