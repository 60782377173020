.navbar {
  background-color: rgba(255, 255, 255, 0.95);
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.navbar-logo {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #333;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.navbar-logo:hover {
  transform: scale(1.05);
}

.navbar-icon {
  width: 40px;
  height: 40px;
  border-radius: 12px;
}

.nav-menu {
  display: flex;
  list-style: none;
  gap: 2.5rem;
}

.nav-button {
  color: #007aff;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 0.7rem 1.5rem;
  border-radius: 25px;
  transition: all 0.3s ease;
  background-color: rgba(0, 122, 255, 0.1);
  border: 2px solid #007aff;
  cursor: pointer;
  font-family: inherit;
}

.nav-button:hover {
  color: #ffffff;
  background-color: #007aff;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.2);
}

.nav-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(0, 122, 255, 0.15);
}

@media (max-width: 768px) {
  .navbar {
    height: 70px;
  }

  .navbar-container {
    padding: 0 1.5rem;
  }

  .navbar-logo {
    font-size: 1.3rem;
  }

  .navbar-icon {
    width: 32px;
    height: 32px;
  }

  .nav-menu {
    gap: 1.5rem;
  }

  .nav-button {
    font-size: 1rem;
    padding: 0.6rem 1.2rem;
  }
}
